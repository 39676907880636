import React from 'react';
import { Router } from 'react-router';
import { createHashHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import { TunnelProvider } from 'react-tunnels';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';

import { store, persistor } from './store';
import i18n from './i18n';
import dateLocaleMap from './i18n/dateLocaleMap';
import AppLayout from './layout/AppLayout';
import { defaultTheme } from './config/theme';
import { getRedirectInfo } from './helpers/urlHelper';
import authActions from './actions/auth';

import appConfig from './config';

const history = createHashHistory();

axios.interceptors.request.use(
  (config) => {
    if (config && config.skipAuthorization) {
      return config;
    }

    let skipResponseFilter = false;
    if (config && config.url && config.url.includes('/auth/sign_in')) {
      skipResponseFilter = true;
    }

    const { credentials } = store.getState().auth;

    const headers = {
      Accept: 'application/json',
      'access-token': credentials.token,
      client: credentials.client,
      expiry: credentials.expiry,
      'token-type': 'Bearer',
      uid: credentials.uid,
    };

    if (!config || (config && !config.skipContentType)) {
      headers['Content-Type'] = config && config.headers ? config.headers['Content-Type'] : 'application/json';
    }

    return { ...config, skipResponseFilter, headers };
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (!error.config.skipResponseFilter) {
      if (error.response.status === 401) {
        store.dispatch(authActions.signOutSuccess());
        return null;
      }
    }
    return Promise.reject(error);
  },
);

const App = () => {
  document.title = appConfig.title;

  return (
    <TunnelProvider>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
            onBeforeLift={() => {
              const { authRedirectPath, authRedirectHeaders } = getRedirectInfo(window.location);
              if (authRedirectHeaders && authRedirectHeaders.uid && authRedirectHeaders.token) {
                store.dispatch({
                  type: 'SET_SESSION_CREDENTIALS',
                  payload: { ...authRedirectHeaders },
                });
                window.history.replaceState(null, null, authRedirectPath);
              }
            }}
          >
            <Router history={history}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLocaleMap['pt-BR']}>
                <MuiThemeProvider theme={defaultTheme}>
                  <CssBaseline />
                  <div className="App">
                    <AppLayout />
                  </div>
                </MuiThemeProvider>
              </MuiPickersUtilsProvider>
            </Router>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </TunnelProvider>
  );
};

export default App;
