// @flow

import createReducer from 'helpers/reducerHelper';

import type { Payload, AgriculturalStepsState } from 'types/agriculturalSteps';

const initialState: AgriculturalStepsState = {
  records: [],
  sorting: [],
  query: '',
  meta: null,
};

export default createReducer(initialState, {
  FETCH_AGRICULTURAL_STEPS_SUCCESS: (state: AgriculturalStepsState, action: { payload: Payload }) => ({
    ...state,
    ...action.payload,
  }),
});
