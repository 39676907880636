export const FETCH_NUTRITIONAL_SYMPTOMS_REQUEST = 'FETCH_NUTRITIONAL_SYMPTOMS_REQUEST';
export const FETCH_NUTRITIONAL_SYMPTOMS_SUCCESS = 'FETCH_NUTRITIONAL_SYMPTOMS_SUCCESS';
export const FETCH_NUTRITIONAL_SYMPTOMS_FAILURE = 'FETCH_NUTRITIONAL_SYMPTOMS_FAILURE';

export const FETCH_NUTRITIONAL_SYMPTOM_REQUEST = 'FETCH_NUTRITIONAL_SYMPTOM_REQUEST';
export const FETCH_NUTRITIONAL_SYMPTOM_SUCCESS = 'FETCH_NUTRITIONAL_SYMPTOM_SUCCESS';
export const FETCH_NUTRITIONAL_SYMPTOM_FAILURE = 'FETCH_NUTRITIONAL_SYMPTOM_FAILURE';
export const CREATE_NUTRITIONAL_SYMPTOM_REQUEST = 'CREATE_NUTRITIONAL_SYMPTOM_REQUEST';
export const CREATE_NUTRITIONAL_SYMPTOM_SUCCESS = 'CREATE_NUTRITIONAL_SYMPTOM_SUCCESS';
export const CREATE_NUTRITIONAL_SYMPTOM_FAILURE = 'CREATE_NUTRITIONAL_SYMPTOM_FAILURE';
export const UPDATE_NUTRITIONAL_SYMPTOM_REQUEST = 'UPDATE_NUTRITIONAL_SYMPTOM_REQUEST';
export const UPDATE_NUTRITIONAL_SYMPTOM_SUCCESS = 'UPDATE_NUTRITIONAL_SYMPTOM_SUCCESS';
export const UPDATE_NUTRITIONAL_SYMPTOM_FAILURE = 'UPDATE_NUTRITIONAL_SYMPTOM_FAILURE';
export const DELETE_NUTRITIONAL_SYMPTOM_REQUEST = 'DELETE_NUTRITIONAL_SYMPTOM_REQUEST';
export const DELETE_NUTRITIONAL_SYMPTOM_SUCCESS = 'DELETE_NUTRITIONAL_SYMPTOM_SUCCESS';
export const DELETE_NUTRITIONAL_SYMPTOM_FAILURE = 'DELETE_NUTRITIONAL_SYMPTOM_FAILURE';

export const RESET_NUTRITIONAL_SYMPTOM = 'RESET_NUTRITIONAL_SYMPTOM';
