// @flow

import createReducer from 'helpers/reducerHelper';

import type { Payload, FuelingsState } from 'types/fuelings';

const initialState: FuelingsState = {
  records: [],
  query: '',
  sorting: [],
  meta: null,
  filters: {},
};

export default createReducer(initialState, {
  FETCH_FUELINGS_SUCCESS: (state: FuelingsState, action: { payload: Payload }) => ({
    ...state,
    ...action.payload,
  }),
});
