// @flow

import { FLASH_SHOW, FLASH_HIDE } from '../constants';

const actions = {
  success(message: string) {
    return actions.show(message, 'success');
  },

  error(message: string) {
    return actions.show(message, 'error');
  },

  info(message: string) {
    return actions.show(message, 'info');
  },

  show(message: string, messageType: string) {
    return { type: FLASH_SHOW, message, messageType };
  },

  hide() {
    return { type: FLASH_HIDE };
  },
};

export default actions;
