import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import actions from 'actions/flash';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
  },
  icon: {
    marginRight: 10,
    fontSize: 30,
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
});

type Props = {
  classes: Object,
  autoHideDuration?: Number,
  vertical?: String,
  horizontal?: String,
  flash: Object,
  close: Function,
};

class FlashMessages extends React.PureComponent<Props> {
  static defaultProps = {
    autoHideDuration: 5000,
    vertical: 'top',
    horizontal: 'right',
  };

  handleClose = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const {
      classes, autoHideDuration, vertical, horizontal, flash,
    } = this.props;

    let icon = <InfoIcon color="secondary" className={classes.icon} />;
    if (flash.messageType === 'success') {
      icon = <SuccessIcon color="primary" className={classes.icon} />;
    } else if (flash.messageType === 'error') {
      icon = <ErrorIcon color="error" className={classes.icon} />;
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        open={flash.open}
        autoHideDuration={autoHideDuration}
        onClose={this.handleClose}
        message={
          <div id="flash-message" style={{ display: 'flex', alignItems: 'center' }}>
            {icon}
            <span>{flash.message}</span>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  flash: state.flash,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(actions.hide()),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FlashMessages),
);
