export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';
export const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_FAILURE = 'FETCH_MEMBER_FAILURE';
export const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';
export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE';
export const CONFIRMATION_MEMBER_REQUEST = 'CONFIRMATION_MEMBER_REQUEST';
export const CONFIRMATION_MEMBER_SUCCESS = 'CONFIRMATION_MEMBER_SUCCESS';
export const CONFIRMATION_MEMBER_FAILURE = 'CONFIRMATION_MEMBER_FAILURE';
export const RESET_MEMBER = 'RESET_MEMBER';
