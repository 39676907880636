import { FLASH_SHOW, FLASH_HIDE } from '../constants';

const initialState = {
  open: false,
  message: null,
  messageType: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FLASH_SHOW:
      return {
        ...state,
        open: true,
        message: action.message,
        messageType: action.messageType,
      };
    case FLASH_HIDE: {
      return {
        ...state,
        open: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
