export const FETCH_GROWTH_VARIETIES_REQUEST = 'FETCH_GROWTH_VARIETIES_REQUEST';
export const FETCH_GROWTH_VARIETIES_SUCCESS = 'FETCH_GROWTH_VARIETIES_SUCCESS';
export const FETCH_GROWTH_VARIETIES_FAILURE = 'FETCH_GROWTH_VARIETIES_FAILURE';

export const FETCH_GROWTH_VARIETY_REQUEST = 'FETCH_GROWTH_VARIETY_REQUEST';
export const FETCH_GROWTH_VARIETY_SUCCESS = 'FETCH_GROWTH_VARIETY_SUCCESS';
export const FETCH_GROWTH_VARIETY_FAILURE = 'FETCH_GROWTH_VARIETY_FAILURE';
export const CREATE_GROWTH_VARIETY_REQUEST = 'CREATE_GROWTH_VARIETY_REQUEST';
export const CREATE_GROWTH_VARIETY_SUCCESS = 'CREATE_GROWTH_VARIETY_SUCCESS';
export const CREATE_GROWTH_VARIETY_FAILURE = 'CREATE_GROWTH_VARIETY_FAILURE';
export const UPDATE_GROWTH_VARIETY_REQUEST = 'UPDATE_GROWTH_VARIETY_REQUEST';
export const UPDATE_GROWTH_VARIETY_SUCCESS = 'UPDATE_GROWTH_VARIETY_SUCCESS';
export const UPDATE_GROWTH_VARIETY_FAILURE = 'UPDATE_GROWTH_VARIETY_FAILURE';
export const DELETE_GROWTH_VARIETY_REQUEST = 'DELETE_GROWTH_VARIETY_REQUEST';
export const DELETE_GROWTH_VARIETY_SUCCESS = 'DELETE_GROWTH_VARIETY_SUCCESS';
export const DELETE_GROWTH_VARIETY_FAILURE = 'DELETE_GROWTH_VARIETY_FAILURE';
export const RESET_GROWTH_VARIETY = 'RESET_GROWTH_VARIETY';
