import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from '../constants/companies';

import { SIGN_OUT_SUCCESS } from '../constants/auth';

const initialState = {
  records: [],
  meta: {},
  query: '',
  sorting: [],
  isFetching: false,
  isFetched: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SIGN_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
