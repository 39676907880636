export const FETCH_GROWTH_STAGES_REQUEST = 'FETCH_GROWTH_STAGES_REQUEST';
export const FETCH_GROWTH_STAGES_SUCCESS = 'FETCH_GROWTH_STAGES_SUCCESS';
export const FETCH_GROWTH_STAGES_FAILURE = 'FETCH_GROWTH_STAGES_FAILURE';

export const FETCH_GROWTH_STAGE_REQUEST = 'FETCH_GROWTH_STAGE_REQUEST';
export const FETCH_GROWTH_STAGE_SUCCESS = 'FETCH_GROWTH_STAGE_SUCCESS';
export const FETCH_GROWTH_STAGE_FAILURE = 'FETCH_GROWTH_STAGE_FAILURE';
export const CREATE_GROWTH_STAGE_REQUEST = 'CREATE_GROWTH_STAGE_REQUEST';
export const CREATE_GROWTH_STAGE_SUCCESS = 'CREATE_GROWTH_STAGE_SUCCESS';
export const CREATE_GROWTH_STAGE_FAILURE = 'CREATE_GROWTH_STAGE_FAILURE';
export const UPDATE_GROWTH_STAGE_REQUEST = 'UPDATE_GROWTH_STAGE_REQUEST';
export const UPDATE_GROWTH_STAGE_SUCCESS = 'UPDATE_GROWTH_STAGE_SUCCESS';
export const UPDATE_GROWTH_STAGE_FAILURE = 'UPDATE_GROWTH_STAGE_FAILURE';
export const DELETE_GROWTH_STAGE_REQUEST = 'DELETE_GROWTH_STAGE_REQUEST';
export const DELETE_GROWTH_STAGE_SUCCESS = 'DELETE_GROWTH_STAGE_SUCCESS';
export const DELETE_GROWTH_STAGE_FAILURE = 'DELETE_GROWTH_STAGE_FAILURE';
export const RESET_GROWTH_STAGE = 'RESET_GROWTH_STAGE';
