// @flow

import createReducer from 'helpers/reducerHelper';

import type { Payload, ConnectionsState } from 'types/connections';

const initialState: ConnectionsState = {
  records: [],
  sorting: [],
  meta: null,
  query: '',
};

export default createReducer(initialState, {
  FETCH_CONNECTIONS_SUCCESS: (state: ConnectionsState, action: { payload: Payload }) => ({
    ...state,
    ...action.payload,
  }),
});
