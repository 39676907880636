// @flow

import createReducer from 'helpers/reducerHelper';

import type { ManufacturersState, Payload } from 'types/vehicle/manufacturers';

export default createReducer(
  {},
  {
    FETCH_VEHICLE_MANUFACTURERS_SUCCESS: (
      state: ManufacturersState,
      action: { payload: Payload },
    ): ManufacturersState => ({
      ...state,
      ...action.payload,
    }),
  },
);
