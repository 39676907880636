import createReducer from 'helpers/reducerHelper';

const initialState = {
  records: [],
  sorting: [],
  filters: {
    query: '',
  },
  meta: null,
};

export default createReducer(initialState, {
  FETCH_ADUBATIONS_SUCCESS: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  UPDATE_ADUBATIONS_FILTERS: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
