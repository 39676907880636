// @flow

import type { Action } from 'types/redux';

type State = { [key: string]: string | void };

export default (state: State = {}, action: Action): State => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'FAILURE' && action.error ? action.error : '',
  };
};
