export const FLASH_SHOW = 'FLASH_SHOW';
export const FLASH_HIDE = 'FLASH_HIDE';

export const UPDATE_FILTER_FARM = 'UPDATE_FILTER_FARM';

export const UPDATE_MAP_SELECTED_FIELD = 'UPDATE_MAP_SELECTED_FIELD';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const RESET_USER = 'RESET_USER';

export const DASHBOARD_FETCH_FIELDS_REQUEST = 'DASHBOARD_FETCH_FIELDS_REQUEST';
export const DASHBOARD_FETCH_FIELDS_SUCCESS = 'DASHBOARD_FETCH_FIELDS_SUCCESS';
export const DASHBOARD_FETCH_FIELDS_FAILURE = 'DASHBOARD_FETCH_FIELDS_FAILURE';
export const DASHBOARD_FETCH_CROPS_REQUEST = 'DASHBOARD_FETCH_CROPS_REQUEST';
export const DASHBOARD_FETCH_CROPS_SUCCESS = 'DASHBOARD_FETCH_CROPS_SUCCESS';
export const DASHBOARD_FETCH_CROPS_FAILURE = 'DASHBOARD_FETCH_CROPS_FAILURE';
export const DASHBOARD_FETCH_REPORTS_REQUEST = 'DASHBOARD_FETCH_REPORTS_REQUEST';
export const DASHBOARD_FETCH_REPORTS_SUCCESS = 'DASHBOARD_FETCH_REPORTS_SUCCESS';
export const DASHBOARD_FETCH_REPORTS_FAILURE = 'DASHBOARD_FETCH_REPORTS_FAILURE';
export const DASHBOARD_FETCH_WATCHER_REPORTS_REQUEST = 'DASHBOARD_FETCH_WATCHER_REPORTS_REQUEST';
export const DASHBOARD_FETCH_WATCHER_REPORTS_SUCCESS = 'DASHBOARD_FETCH_WATCHER_REPORTS_SUCCESS';
export const DASHBOARD_FETCH_WATCHER_REPORTS_FAILURE = 'DASHBOARD_FETCH_WATCHER_REPORTS_FAILURE';
export const DASHBOARD_FETCH_PROJECTS_REQUEST = 'DASHBOARD_FETCH_PROJECTS_REQUEST';
export const DASHBOARD_FETCH_PROJECTS_SUCCESS = 'DASHBOARD_FETCH_PROJECTS_SUCCESS';
export const DASHBOARD_FETCH_PROJECTS_FAILURE = 'DASHBOARD_FETCH_PROJECTS_FAILURE';
export const DASHBOARD_FETCH_FLEET_REQUEST = 'DASHBOARD_FETCH_FLEET_REQUEST';
export const DASHBOARD_FETCH_FLEET_SUCCESS = 'DASHBOARD_FETCH_FLEET_SUCCESS';
export const DASHBOARD_FETCH_FLEET_FAILURE = 'DASHBOARD_FETCH_FLEET_FAILURE';

export const FETCH_FIELDS_REQUEST = 'FETCH_FIELDS_REQUEST';
export const FETCH_FIELDS_SUCCESS = 'FETCH_FIELDS_SUCCESS';
export const FETCH_FIELDS_FAILURE = 'FETCH_FIELDS_FAILURE';
export const FETCH_TOTAL_AREA_BY_GROWTH_REQUEST = 'FETCH_TOTAL_AREA_BY_GROWTH_REQUEST';
export const FETCH_TOTAL_AREA_BY_GROWTH_SUCCESS = 'FETCH_TOTAL_AREA_BY_GROWTH_SUCCESS';
export const FETCH_TOTAL_AREA_BY_GROWTH_FAILURE = 'FETCH_TOTAL_AREA_BY_GROWTH_FAILURE';
export const FETCH_FIELD_REQUEST = 'FETCH_FIELD_REQUEST';
export const FETCH_FIELD_SUCCESS = 'FETCH_FIELD_SUCCESS';
export const FETCH_FIELD_FAILURE = 'FETCH_FIELD_FAILURE';
export const CREATE_FIELD_REQUEST = 'CREATE_FIELD_REQUEST';
export const CREATE_FIELD_SUCCESS = 'CREATE_FIELD_SUCCESS';
export const CREATE_FIELD_FAILURE = 'CREATE_FIELD_FAILURE';
export const UPDATE_FIELD_REQUEST = 'UPDATE_FIELD_REQUEST';
export const UPDATE_FIELD_SUCCESS = 'UPDATE_FIELD_SUCCESS';
export const UPDATE_FIELD_FAILURE = 'UPDATE_FIELD_FAILURE';
export const DELETE_FIELD_REQUEST = 'DELETE_FIELD_REQUEST';
export const DELETE_FIELD_SUCCESS = 'DELETE_FIELD_SUCCESS';
export const DELETE_FIELD_FAILURE = 'DELETE_FIELD_FAILURE';
export const RESET_FIELD = 'RESET_FIELD';

export const FETCH_CROPS_REQUEST = 'FETCH_CROPS_REQUEST';
export const FETCH_CROPS_SUCCESS = 'FETCH_CROPS_SUCCESS';
export const FETCH_CROPS_FAILURE = 'FETCH_CROPS_FAILURE';
export const FETCH_CROP_REQUEST = 'FETCH_CROP_REQUEST';
export const FETCH_CROP_SUCCESS = 'FETCH_CROP_SUCCESS';
export const FETCH_CROP_FAILURE = 'FETCH_CROP_FAILURE';
export const CREATE_CROP_REQUEST = 'CREATE_CROP_REQUEST';
export const CREATE_CROP_SUCCESS = 'CREATE_CROP_SUCCESS';
export const CREATE_CROP_FAILURE = 'CREATE_CROP_FAILURE';
export const UPDATE_CROP_REQUEST = 'UPDATE_CROP_REQUEST';
export const UPDATE_CROP_SUCCESS = 'UPDATE_CROP_SUCCESS';
export const UPDATE_CROP_FAILURE = 'UPDATE_CROP_FAILURE';
export const DELETE_CROP_REQUEST = 'DELETE_CROP_REQUEST';
export const DELETE_CROP_SUCCESS = 'DELETE_CROP_SUCCESS';
export const DELETE_CROP_FAILURE = 'DELETE_CROP_FAILURE';
export const RESET_CROP = 'RESET_CROP';
export const RESET_CROPS = 'RESET_CROPS';
