// @flow

import createReducer from 'helpers/reducerHelper';

import type { Manufacturer, Payload } from 'types/vehicle/manufacturer';

export default createReducer(
  {},
  {
    FETCH_VEHICLE_MANUFACTURER_SUCCESS: (
      state: Manufacturer,
      action: {
        payload: Payload,
      },
    ): Manufacturer => action.payload.vehicle_manufacturer,
  },
);
