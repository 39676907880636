import { combineReducers } from 'redux';
import { resourceReducer } from 'redux-resource';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { reset } from 'redux-resource-plugins';
import { pagination, sort, filter } from 'agro-common/es/resources/plugins';
import { reducer as flashReducer } from 'agro-common/es/flash';
import flash from './flash';
import auth from './auth';
import app from './app';
import map from './map';
import user from './user';
import users from './users';
import growth from './growth';
import growths from './growths';
import growthStage from './growthStage';
import growthStages from './growthStages';
import growthVariety from './growthVariety';
import growthVarieties from './growthVarieties';
import nutritionalSymptom from './nutritionalSymptom';
import nutritionalSymptoms from './nutritionalSymptoms';
import siganematoideSymptom from './siganematoideSymptom';
import siganematoideSymptoms from './siganematoideSymptoms';
import threat from './threat';
import threats from './threats';
import companies from './companies';
import company from './company';
import dashboard from './dashboard';
import farm from './farm';
import farmGroup from './farmGroup';
import field from './field';
import fields from './fields';
import totalAreaByGrowth from './totalAreaByGrowth';
import fieldCrop from './fieldCrop';
import fieldCrops from './fieldCrops';
import nutrientBalance from './nutrientBalance';
import adubation from './adubation';
import adubations from './adubations';
import crop from './crop';
import crops from './crops';
import report from './report';
import watcherReport from './watcherReport';
import watcherReports from './watcherReports';
import project from './project';
import projects from './projects';
import analysis from './analysis';
import member from './member';
import members from './members';
import fuelings from './fuelings';
import fueling from './fueling';
import vehicles from './vehicles';
import vehicle from './vehicle';
import vehicleCategories from './vehicle/categories';
import vehicleCategory from './vehicle/category';
import vehicleManufacturers from './vehicle/manufacturers';
import vehicleManufacturer from './vehicle/manufacturer';
import agriculturalStep from './agriculturalStep';
import agriculturalSteps from './agriculturalSteps';
import gasStations from './gasStations';
import connections from './connections';
import connection from './connection';

import resource from './resource';
import error from './error';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['app'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'credentials'],
};

const reducers = {
  auth: persistReducer(authPersistConfig, auth),
  flash,
  flashReducer,
  app,
  map,
  user,
  users,
  growth,
  growths,
  growthStage,
  growthStages,
  growthVariety,
  growthVarieties,
  nutritionalSymptom,
  nutritionalSymptoms,
  threat,
  threats,
  siganematoideSymptom,
  siganematoideSymptoms,
  company,
  companies,
  dashboard,
  farm,
  farms: resourceReducer('farms'),
  farmGroup,
  farmGroups: resourceReducer('farmGroups', {
    plugins: [reset],
  }),
  field,
  fields,
  totalAreaByGrowth,
  fieldCrop,
  fieldCrops,
  nutrientBalance,
  adubation,
  adubations,
  crop,
  crops,
  report,
  reports: resourceReducer('reports', {
    plugins: [pagination, sort, filter, reset],
  }),
  watcherReport,
  watcherReports,
  project,
  projects,
  analysis,
  member,
  members,
  fuelings,
  fueling,
  vehicles,
  vehicle,
  vehicleCategories,
  vehicleCategory,
  vehicleManufacturers,
  vehicleManufacturer,
  agriculturalStep,
  agriculturalSteps,
  gasStations,
  connections,
  connection,
  resource,
  error,
};

const allReducers = persistReducer(persistConfig, combineReducers(reducers));
export default allReducers;
