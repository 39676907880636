import {
  FETCH_MEMBER_REQUEST,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_FAILURE,
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  RESET_MEMBER,
} from '../constants/members';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isSaving: false,
  isSaved: false,
  isDeleting: false,
  isDeleted: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_MEMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATE_MEMBER_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: null,
      };
    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isCreating: false,
        isCreated: true,
        error: null,
      };
    case CREATE_MEMBER_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: action.error,
      };
    case UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        error: null,
      };
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isSaving: false,
        isSaved: true,
        error: null,
      };
    case UPDATE_MEMBER_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        error: action.error,
      };
    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        error: null,
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isDeleting: false,
        isDeleted: true,
        error: null,
      };
    case DELETE_MEMBER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error,
      };
    case RESET_MEMBER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
