import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE } from '../constants';

const initialState = {
  records: [],
  meta: {},
  query: '',
  sorting: [],
  isFetching: false,
  isFetched: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    }
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
