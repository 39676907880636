export const FETCH_FIELD_CROPS_REQUEST = 'FETCH_FIELD_CROPS_REQUEST';
export const FETCH_FIELD_CROPS_SUCCESS = 'FETCH_FIELD_CROPS_SUCCESS';
export const FETCH_FIELD_CROPS_FAILURE = 'FETCH_FIELD_CROPS_FAILURE';

export const FETCH_FIELD_CROP_REQUEST = 'FETCH_FIELD_CROP_REQUEST';
export const FETCH_FIELD_CROP_SUCCESS = 'FETCH_FIELD_CROP_SUCCESS';
export const FETCH_FIELD_CROP_FAILURE = 'FETCH_FIELD_CROP_FAILURE';
export const CREATE_FIELD_CROP_REQUEST = 'CREATE_FIELD_CROP_REQUEST';
export const CREATE_FIELD_CROP_SUCCESS = 'CREATE_FIELD_CROP_SUCCESS';
export const CREATE_FIELD_CROP_FAILURE = 'CREATE_FIELD_CROP_FAILURE';
export const UPDATE_FIELD_CROP_REQUEST = 'UPDATE_FIELD_CROP_REQUEST';
export const UPDATE_FIELD_CROP_SUCCESS = 'UPDATE_FIELD_CROP_SUCCESS';
export const UPDATE_FIELD_CROP_FAILURE = 'UPDATE_FIELD_CROP_FAILURE';
export const DELETE_FIELD_CROP_REQUEST = 'DELETE_FIELD_CROP_REQUEST';
export const DELETE_FIELD_CROP_SUCCESS = 'DELETE_FIELD_CROP_SUCCESS';
export const DELETE_FIELD_CROP_FAILURE = 'DELETE_FIELD_CROP_FAILURE';
