// @flow

import createReducer from 'helpers/reducerHelper';

import type { Fueling, Payload } from 'types/fueling';

export default createReducer(
  {},
  {
    FETCH_FUELING_SUCCESS: (state: Fueling, action: { payload: Payload }): Fueling => action.payload.record,
  },
);
