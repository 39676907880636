export default {
  colors: [
    '#2b908f',
    '#90ee7e',
    '#f45b5b',
    '#7798BF',
    '#aaeeee',
    '#ff0066',
    '#eeaaee',
    '#55BF3B',
    '#DF5353',
    '#7798BF',
    '#aaeeee',
  ],
  chart: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    plotBackgroundColor: 'transparent',
    plotShadow: false,
    plotBorderWidth: 0,
  },
  title: {
    style: {
      color: '#E0E0E3', // #3E576F',
      fontSize: '16px',
    },
  },
  subtitle: {
    style: {
      color: '#E0E0E3',
    },
  },
  xAxis: {
    gridLineWidth: 0,
    gridLineColor: '#707073',
    lineColor: '#707073',
    tickColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px',
      },
    },
    title: {
      style: {
        color: '#A0A0A3',
        fontWeight: 'bold',
      },
    },
  },
  yAxis: {
    minorTickInterval: null,
    lineColor: '#707073',
    lineWidth: 1,
    tickWidth: 1,
    tickColor: '#707073',
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px',
      },
    },
    title: {
      style: {
        color: '#A0A0A3',
        fontWeight: 'bold',
      },
    },
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    style: {
      color: '#F0F0F0',
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: '#EEE',
      },
    },
  },
  legend: {
    itemStyle: {
      color: '#E0E0E3',
      fontSize: '12px',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#606063',
    },
  },
  labels: {
    style: {
      color: '#707073',
    },
  },

  navigation: {
    buttonOptions: {
      theme: {
        stroke: '#CCCCCC',
      },
    },
  },
};
