// @flow

import createReducer from 'helpers/reducerHelper';

import type { VehicleCategoriesState, Payload } from 'types/vehicle/categories';

export default createReducer(
  {},
  {
    FETCH_VEHICLE_CATEGORIES_SUCCESS: (
      state: VehicleCategoriesState,
      action: { payload: Payload },
    ): VehicleCategoriesState => ({
      ...state,
      ...action.payload,
    }),
  },
);
