import {
  FETCH_WATCHER_REPORT_REQUEST,
  FETCH_WATCHER_REPORT_SUCCESS,
  FETCH_WATCHER_REPORT_FAILURE,
  DELETE_WATCHER_REPORT_REQUEST,
  DELETE_WATCHER_REPORT_SUCCESS,
  DELETE_WATCHER_REPORT_FAILURE,
  RESET_WATCHER_REPORT,
} from '../constants/watcherReports';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isDeleting: false,
  isDeleted: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCHER_REPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_WATCHER_REPORT_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_WATCHER_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case DELETE_WATCHER_REPORT_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        error: null,
      };
    case DELETE_WATCHER_REPORT_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isDeleting: false,
        isDeleted: true,
        error: null,
      };
    case DELETE_WATCHER_REPORT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error,
      };
    case RESET_WATCHER_REPORT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
