import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RESET_USER,
} from '../constants';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isSaving: false,
  isSaved: false,
  isDeleting: false,
  isDeleted: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isCreating: false,
        isCreated: true,
        error: null,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: action.error,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        error: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isSaving: false,
        isSaved: true,
        error: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        error: action.error,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isDeleting: false,
        isDeleted: true,
        error: null,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error,
      };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
