import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  CONFIRMATION_MEMBER_REQUEST,
  CONFIRMATION_MEMBER_SUCCESS,
  CONFIRMATION_MEMBER_FAILURE,
} from '../constants/members';

const initialState = {
  records: [],
  meta: {},
  query: '',
  sorting: [],
  isFetching: false,
  isFetched: false,
  isConfirmationSending: false,
  isConfirmationSent: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_MEMBERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CONFIRMATION_MEMBER_REQUEST:
      return {
        ...state,
        isConfirmationSending: true,
        isConfirmationSent: false,
        error: null,
      };
    case CONFIRMATION_MEMBER_SUCCESS:
      return {
        ...state,
        isConfirmationSending: false,
        isConfirmationSent: true,
      };
    case CONFIRMATION_MEMBER_FAILURE:
      return {
        ...state,
        isConfirmationSending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
