import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import reducers from 'reducers/index';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];

/* eslint-disable */
if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');

  middlewares.push(logger);
}
/* eslint-enable */

const store = createStore(reducers, composeEnhancer(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

export { store, persistor };
