import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format as formatDate } from 'date-fns';
import dateLocaleMap from './dateLocaleMap';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
      format: (value, format, locale) => {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'number') return value != null ? new Intl.NumberFormat(locale).format(value) : '-';
        if (value instanceof Date) return formatDate(value, format, { locale: dateLocaleMap[locale] });

        return value;
      },
    },

    react: {
      wait: true,
    },
  });

export default i18n;
