import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Highcharts from 'highcharts';
import _ from 'lodash';
import highchartsDefaultTheme from '../themes/highcharts/default';
import highchartsDarkTheme from '../themes/highcharts/dark';

export const defaultValues = {
  palette: {
    type: 'light',
    primary: {
      main: '#131e2a',
    },
    secondary: {
      main: '#f37422',
    },
    error: red,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#131e2a',
        color: '#f5f6f6',
      },
    },
  },
  typography: {
    useNextVariants: true,
    title: {
      fontSize: '1.2em',
    },
  },
};

export const defaultTheme = createMuiTheme(_.cloneDeep(defaultValues));

export const createTheme = (values) => {
  if (values && values.palette && values.palette.type === 'dark') {
    Highcharts.setOptions(highchartsDarkTheme);
  } else {
    Highcharts.setOptions(highchartsDefaultTheme);
  }
  return createMuiTheme(_.merge({}, defaultValues, values));
};
