import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  RESET_COMPANY,
} from '../constants/companies';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isSaving: false,
  isSaved: false,
  isDeleting: false,
  isDeleted: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_COMPANY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATE_COMPANY_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: null,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isCreating: false,
        isCreated: true,
        error: null,
      };
    case CREATE_COMPANY_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: action.error,
      };
    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        error: false,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isSaving: false,
        isSaved: true,
        error: false,
      };
    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        error: action.error,
      };
    case DELETE_COMPANY_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        error: null,
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
        error: null,
      };
    case DELETE_COMPANY_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error,
      };
    case RESET_COMPANY:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
