export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const FETCH_PROJECT_ANALYSIS_REQUEST = 'FETCH_PROJECT_ANALYSIS_REQUEST';
export const FETCH_PROJECT_ANALYSIS_SUCCESS = 'FETCH_PROJECT_ANALYSIS_SUCCESS';
export const FETCH_PROJECT_ANALYSIS_FAILURE = 'FETCH_PROJECT_ANALYSIS_FAILURE';
export const TOGGLE_ANALYSIS_WITH_CORRELATION = 'TOGGLE_ANALYSIS_WITH_CORRELATION';
export const RESET_PROJECT_ANALYSIS = 'RESET_PROJECT_ANALYSIS';

export const UPDATE_PROJECT_ENVIRONMENT_MAP_REQUEST = 'UPDATE_PROJECT_ENVIRONMENT_MAP_REQUEST';
export const UPDATE_PROJECT_ENVIRONMENT_MAP_SUCCESS = 'UPDATE_PROJECT_ENVIRONMENT_MAP_SUCCESS';
export const UPDATE_PROJECT_ENVIRONMENT_MAP_FAILURE = 'UPDATE_PROJECT_ENVIRONMENT_MAP_FAILURE';

export const UPDATE_PROJECT_GENERAL_CONSIDERATIONS_REQUEST = 'UPDATE_PROJECT_GENERAL_CONSIDERATIONS_REQUEST';
export const UPDATE_PROJECT_GENERAL_CONSIDERATIONS_SUCCESS = 'UPDATE_PROJECT_GENERAL_CONSIDERATIONS_SUCCESS';
export const UPDATE_PROJECT_GENERAL_CONSIDERATIONS_FAILURE = 'UPDATE_PROJECT_GENERAL_CONSIDERATIONS_FAILURE';

export const CREATE_PROJECT_SECTION_REQUEST = 'CREATE_PROJECT_SECTION_REQUEST';
export const CREATE_PROJECT_SECTION_SUCCESS = 'CREATE_PROJECT_SECTION_SUCCESS';
export const CREATE_PROJECT_SECTION_FAILURE = 'CREATE_PROJECT_SECTION_FAILURE';

export const UPDATE_PROJECT_SECTION_REQUEST = 'UPDATE_PROJECT_SECTION_REQUEST';
export const UPDATE_PROJECT_SECTION_SUCCESS = 'UPDATE_PROJECT_SECTION_SUCCESS';
export const UPDATE_PROJECT_SECTION_FAILURE = 'UPDATE_PROJECT_SECTION_FAILURE';

export const UPDATE_PROJECT_SECTION_POSITION_REQUEST = 'UPDATE_PROJECT_SECTION_POSITION_REQUEST';
export const UPDATE_PROJECT_SECTION_POSITION_SUCCESS = 'UPDATE_PROJECT_SECTION_POSITION_SUCCESS';
export const UPDATE_PROJECT_SECTION_POSITION_FAILURE = 'UPDATE_PROJECT_SECTION_POSITION_FAILURE';

export const DELETE_PROJECT_SECTION_REQUEST = 'DELETE_PROJECT_SECTION_REQUEST';
export const DELETE_PROJECT_SECTION_SUCCESS = 'DELETE_PROJECT_SECTION_SUCCESS';
export const DELETE_PROJECT_SECTION_FAILURE = 'DELETE_PROJECT_SECTION_FAILURE';

export const FERTIANALISE = 'Fertianalise';
export const SIGANEMATOIDE = 'Siganematoide';
export const NUTRIAGRO = 'Nutriagro';
