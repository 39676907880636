import { UPDATE_MAP_SELECTED_FIELD } from '../constants';

const initialState = {
  selectedFieldId: null,
  selectedField: null,
};

const map = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MAP_SELECTED_FIELD:
      return {
        ...state,
        selectedFieldId: action.field ? action.field.id : null,
        selectedField: action.field,
      };
    default:
      return state;
  }
};

export default map;
