import {
  DASHBOARD_FETCH_FIELDS_REQUEST,
  DASHBOARD_FETCH_FIELDS_SUCCESS,
  DASHBOARD_FETCH_FIELDS_FAILURE,
  DASHBOARD_FETCH_CROPS_REQUEST,
  DASHBOARD_FETCH_CROPS_SUCCESS,
  DASHBOARD_FETCH_CROPS_FAILURE,
  DASHBOARD_FETCH_REPORTS_REQUEST,
  DASHBOARD_FETCH_REPORTS_SUCCESS,
  DASHBOARD_FETCH_REPORTS_FAILURE,
  DASHBOARD_FETCH_WATCHER_REPORTS_REQUEST,
  DASHBOARD_FETCH_WATCHER_REPORTS_SUCCESS,
  DASHBOARD_FETCH_WATCHER_REPORTS_FAILURE,
  DASHBOARD_FETCH_PROJECTS_REQUEST,
  DASHBOARD_FETCH_PROJECTS_SUCCESS,
  DASHBOARD_FETCH_PROJECTS_FAILURE,
  DASHBOARD_FETCH_FLEET_REQUEST,
  DASHBOARD_FETCH_FLEET_SUCCESS,
  DASHBOARD_FETCH_FLEET_FAILURE,
} from '../constants';

const initialState = {
  fields: {
    records: [],
    isFetching: false,
    isFetched: false,
    error: null,
  },
  crops: {
    records: [],
    isFetching: false,
    isFetched: false,
    error: null,
  },
  reports: {
    records: [],
    isFetching: false,
    isFetched: false,
    error: null,
  },
  watcherReports: {
    records: [],
    isFetching: false,
    isFetched: false,
    error: null,
  },
  projects: {
    records: [],
    isFetching: false,
    isFetched: false,
    error: null,
  },
  fleet: {
    fuelings: [],
    spent_fuel: null,
    available_fuel: null,
    size: null,
    isFetching: false,
    isFetched: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_FETCH_FIELDS_REQUEST:
      return {
        ...state,
        fields: {
          ...state.fields,
          isFetching: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_FIELDS_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          records: action.payload,
          isFetching: false,
          isFetched: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_FIELDS_FAILURE:
      return {
        ...state,
        fields: {
          ...state.fields,
          isFetching: false,
          error: action.error,
        },
      };
    case DASHBOARD_FETCH_CROPS_REQUEST:
      return {
        ...state,
        crops: {
          ...state.crops,
          isFetching: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_CROPS_SUCCESS:
      return {
        ...state,
        crops: {
          ...state.crops,
          records: action.payload,
          isFetching: false,
          isFetched: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_CROPS_FAILURE:
      return {
        ...state,
        crops: {
          ...state.crops,
          isFetching: false,
          error: action.error,
        },
      };
    case DASHBOARD_FETCH_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          isFetching: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          records: action.payload,
          isFetching: false,
          isFetched: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_REPORTS_FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          isFetching: false,
          error: action.error,
        },
      };
    case DASHBOARD_FETCH_WATCHER_REPORTS_REQUEST:
      return {
        ...state,
        watcherReports: {
          ...state.watcherReports,
          isFetching: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_WATCHER_REPORTS_SUCCESS:
      return {
        ...state,
        watcherReports: {
          ...state.watcherReports,
          records: action.payload,
          isFetching: false,
          isFetched: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_WATCHER_REPORTS_FAILURE:
      return {
        ...state,
        watcherReports: {
          ...state.watcherReports,
          isFetching: false,
          error: action.error,
        },
      };
    case DASHBOARD_FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        projects: {
          ...state.projects,
          isFetching: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: {
          ...state.projects,
          records: action.payload,
          isFetching: false,
          isFetched: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        projects: {
          ...state.projects,
          isFetching: false,
          error: action.error,
        },
      };
    case DASHBOARD_FETCH_FLEET_REQUEST:
      return {
        ...state,
        fleet: {
          ...state.fleet,
          isFetching: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_FLEET_SUCCESS:
      return {
        ...state,
        fleet: {
          ...state.fleet,
          vehicles: action.payload.vehicles,
          spent_fuel: action.payload.spent_fuel,
          available_fuel: action.payload.available_fuel,
          size: action.payload.size,
          isFetching: false,
          isFetched: true,
          error: null,
        },
      };
    case DASHBOARD_FETCH_FLEET_FAILURE:
      return {
        ...state,
        fleet: {
          ...state.fleet,
          isFetching: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;
