import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
} from '../constants/auth';
import * as api from '../api/auth';

const actions = {
  signInRequest() {
    return { type: SIGN_IN_REQUEST };
  },

  signInSuccess(user, credentials) {
    return {
      type: SIGN_IN_SUCCESS,
      payload: { user, credentials },
    };
  },

  signInFailure(error) {
    return { type: SIGN_IN_FAILURE, error };
  },

  signIn(email, password) {
    return (dispatch) => {
      dispatch(actions.signInRequest());
      return api
        .signIn(email, password)
        .then((resp) => {
          const result = resp.data;
          dispatch(
            actions.signInSuccess(result.data, {
              token: resp.headers['access-token'],
              client: resp.headers.client,
              expiry: resp.headers.expiry,
              uid: resp.headers.uid,
            }),
          );
        })
        .catch((e) => {
          if (e) {
            dispatch(
              actions.signInFailure(
                (e.response.data.errors && e.response.data.errors[0])
                  || e.response.data.message
                  || e.response.statusText,
              ),
            );
          }
        });
    };
  },

  signOutRequest() {
    return { type: SIGN_OUT_REQUEST };
  },

  signOutSuccess() {
    return { type: SIGN_OUT_SUCCESS };
  },

  signOutFailure(error) {
    return { type: SIGN_OUT_FAILURE, error };
  },

  signOut() {
    return (dispatch) => {
      dispatch(actions.signOutRequest());
      api
        .signOut()
        .then(() => {
          dispatch(actions.signOutSuccess());
        })
        .catch((e) => {
          if (e.response) {
            dispatch(
              actions.signOutFailure(
                (e.response.data.errors && e.response.data.errors[0])
                  || e.response.data.message
                  || e.response.statusText,
              ),
            );
          }
        });
    };
  },

  validateTokenRequest() {
    return { type: VALIDATE_TOKEN_REQUEST };
  },

  validateTokenSuccess(user) {
    return { type: VALIDATE_TOKEN_SUCCESS, payload: user };
  },

  validateTokenFailure(error) {
    return { type: VALIDATE_TOKEN_FAILURE, error };
  },

  validateToken() {
    return (dispatch) => {
      dispatch(actions.validateTokenRequest());
      api
        .validateToken()
        .then((resp) => {
          const result = resp.data;
          dispatch(actions.validateTokenSuccess(result.data));
        })
        .catch((e) => {
          if (e.response) {
            dispatch(
              actions.validateTokenFailure(
                (e.response.data.errors && e.response.data.errors[0])
                  || e.response.data.message
                  || e.response.statusText,
              ),
            );
          }
        });
    };
  },

  forgotPasswordRequest() {
    return { type: FORGOT_PASSWORD_REQUEST };
  },

  forgotPasswordSuccess(message) {
    return { type: FORGOT_PASSWORD_SUCCESS, payload: { message } };
  },

  forgotPasswordFailure(error) {
    return { type: FORGOT_PASSWORD_FAILURE, payload: { error } };
  },

  forgotPassword(email) {
    return (dispatch) => {
      dispatch(actions.forgotPasswordRequest());
      return api
        .forgotPassword(email)
        .then((resp) => {
          const result = resp.data;
          dispatch(actions.forgotPasswordSuccess(result.message));
        })
        .catch((e) => {
          if (e.response) {
            const error = (e.response.data.errors && e.response.data.errors[0]) || e.response.statusText;
            dispatch(actions.forgotPasswordFailure(error));
            return e.response.data.errors;
          }
          return null;
        });
    };
  },

  resetPasswordRequest() {
    return { type: RESET_PASSWORD_REQUEST };
  },

  resetPasswordSuccess(message) {
    return { type: RESET_PASSWORD_SUCCESS, payload: { message } };
  },

  resetPasswordFailure(error) {
    return { type: RESET_PASSWORD_FAILURE, payload: { error } };
  },

  resetPassword(password, passwordConfirmation) {
    return (dispatch) => {
      dispatch(actions.resetPasswordRequest());
      return api
        .resetPassword(password, passwordConfirmation)
        .then((resp) => {
          const result = resp.data;
          dispatch(actions.resetPasswordSuccess(result.message));
        })
        .catch((e) => {
          if (e.response) {
            const error = (e.response.data.errors
                && e.response.data.errors.full_messages
                && e.response.data.errors.full_messages[0])
              || e.response.statusText;
            dispatch(actions.resetPasswordFailure(error));
            return e.response.data.errors;
          }
          return null;
        });
    };
  },

  updateAccountRequest() {
    return { type: UPDATE_ACCOUNT_REQUEST };
  },

  updateAccountSuccess(user) {
    return { type: UPDATE_ACCOUNT_SUCCESS, payload: { user } };
  },

  updateAccountFailure(error) {
    return { type: UPDATE_ACCOUNT_FAILURE, payload: { error } };
  },

  updateAccount(values) {
    return (dispatch) => {
      dispatch(actions.updateAccountRequest());
      return api
        .updateAccount(values)
        .then((resp) => {
          const result = resp.data;
          dispatch(actions.updateAccountSuccess(result && result.data));
        })
        .catch((e) => {
          if (e.response) {
            const error = (e.response.data.errors
                && e.response.data.errors.full_messages
                && e.response.data.errors.full_messages[0])
              || e.response.statusText;
            dispatch(actions.updateAccountFailure(error));
            return e.response.data.errors;
          }
          return null;
        });
    };
  },
};

export default actions;
