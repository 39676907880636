import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_SUCCESS,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
} from '../constants/auth';

const initialState = {
  user: null,
  credentials: {
    uid: null,
    token: null,
    client: null,
    expiry: null,
  },
  isValidating: false,
  isValidated: false,
  isForgotPasswordSending: false,
  isForgotPasswordSended: false,
  isPasswordSaving: false,
  isPasswordSaved: false,
  isAccountSaved: false,
  message: null,
  error: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SESSION_CREDENTIALS': {
      return { ...state, credentials: action.payload };
    }
    case SIGN_IN_REQUEST:
      return {
        ...state,
        message: null,
        error: null,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        credentials: action.payload.credentials,
        isValidating: false,
        isValidated: true,
        message: null,
        error: null,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        message: null,
        error: action.error,
      };
    case VALIDATE_TOKEN_REQUEST:
      return {
        ...state,
        isValidating: true,
        isValidated: false,
        message: null,
        error: null,
      };
    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isValidating: false,
        isValidated: true,
        message: null,
        error: null,
      };
    case VALIDATE_TOKEN_FAILURE:
      return initialState;
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isForgotPasswordSending: true,
        isForgotPasswordSended: false,
        message: null,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isForgotPasswordSending: false,
        isForgotPasswordSended: true,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        ...action.payload,
        isForgotPasswordSending: false,
        isForgotPasswordSended: false,
        message: null,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isPasswordSaving: true,
        isPasswordSaved: false,
        message: null,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isPasswordSaving: false,
        isPasswordSaved: true,
        error: null,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        ...action.payload,
        isPasswordSaving: false,
        isPasswordSaved: false,
        message: null,
      };
    case UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isAccountSaved: false,
        error: null,
      };
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAccountSaved: true,
      };
    case UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        ...action.payload,
        isAccountSaved: false,
      };
    case SIGN_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default auth;
