import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  fullscreen: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  fixed: {
    position: 'fixed',
  },
};

type Props = {
  classes: Object,
  fullscreen: Boolean,
  fixed: Boolean,
};

const Loading = ({ classes, fullscreen, fixed }: Props) => (
  <div className={classNames([classes.root], { [classes.fullscreen]: fullscreen }, { [classes.fixed]: fixed })}>
    <CircularProgress />
  </div>
);

export default withStyles(styles)(Loading);
