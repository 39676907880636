import createReducer from 'helpers/reducerHelper';

const initialState = {
  records: [],
  sorting: [],
  filters: {
    query: '',
  },
  meta: null,
};

export default createReducer(initialState, {
  FETCH_FIELDS_SUCCESS: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  UPDATE_FIELDS_FILTERS: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  RESET_FIELDS: () => initialState,
});
