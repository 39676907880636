// @flow

import createReducer from 'helpers/reducerHelper';

import type { Payload, GasStationsState } from 'types/gasStations';

const initialState: GasStationsState = {
  records: [],
  query: '',
  sorting: [],
  meta: null,
};

export default createReducer(initialState, {
  FETCH_GAS_STATIONS_SUCCESS: (state: GasStationsState, action: { payload: Payload }) => ({
    ...state,
    ...action.payload,
  }),
});
