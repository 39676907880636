import {
  FETCH_SIGANEMATOIDE_SYMPTOM_REQUEST,
  FETCH_SIGANEMATOIDE_SYMPTOM_SUCCESS,
  FETCH_SIGANEMATOIDE_SYMPTOM_FAILURE,
  CREATE_SIGANEMATOIDE_SYMPTOM_REQUEST,
  CREATE_SIGANEMATOIDE_SYMPTOM_SUCCESS,
  CREATE_SIGANEMATOIDE_SYMPTOM_FAILURE,
  UPDATE_SIGANEMATOIDE_SYMPTOM_REQUEST,
  UPDATE_SIGANEMATOIDE_SYMPTOM_SUCCESS,
  UPDATE_SIGANEMATOIDE_SYMPTOM_FAILURE,
  DELETE_SIGANEMATOIDE_SYMPTOM_REQUEST,
  DELETE_SIGANEMATOIDE_SYMPTOM_SUCCESS,
  DELETE_SIGANEMATOIDE_SYMPTOM_FAILURE,
  RESET_SIGANEMATOIDE_SYMPTOM,
} from '../constants/siganematoideSymptoms';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isSaving: false,
  isSaved: false,
  isDeleting: false,
  isDeleted: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIGANEMATOIDE_SYMPTOM_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_SIGANEMATOIDE_SYMPTOM_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_SIGANEMATOIDE_SYMPTOM_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATE_SIGANEMATOIDE_SYMPTOM_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: null,
      };
    case CREATE_SIGANEMATOIDE_SYMPTOM_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isCreating: false,
        isCreated: true,
        error: null,
      };
    case CREATE_SIGANEMATOIDE_SYMPTOM_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: action.error,
      };
    case UPDATE_SIGANEMATOIDE_SYMPTOM_REQUEST:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        error: null,
      };
    case UPDATE_SIGANEMATOIDE_SYMPTOM_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isSaving: false,
        isSaved: true,
        error: null,
      };
    case UPDATE_SIGANEMATOIDE_SYMPTOM_FAILURE:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        error: action.error,
      };
    case DELETE_SIGANEMATOIDE_SYMPTOM_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        error: null,
      };
    case DELETE_SIGANEMATOIDE_SYMPTOM_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isDeleting: false,
        isDeleted: true,
        error: null,
      };
    case DELETE_SIGANEMATOIDE_SYMPTOM_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error,
      };
    case RESET_SIGANEMATOIDE_SYMPTOM:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
