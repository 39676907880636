import {
  FETCH_TOTAL_AREA_BY_GROWTH_REQUEST,
  FETCH_TOTAL_AREA_BY_GROWTH_SUCCESS,
  FETCH_TOTAL_AREA_BY_GROWTH_FAILURE,
} from '../constants';

const initialState = {
  records: [],
  isFetching: false,
  isFetched: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOTAL_AREA_BY_GROWTH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_TOTAL_AREA_BY_GROWTH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_TOTAL_AREA_BY_GROWTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
