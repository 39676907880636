import axios from 'axios';
import config from '../config';

function userToFormData(user) {
  const formData = new FormData();

  formData.append('name', user.name);
  formData.append('locale', user.locale);

  if (user.image_file) {
    if (user.image_file.file) {
      formData.append('image', user.image_file.file);
    }
  } else {
    formData.append('remove_image', true);
  }

  return formData;
}


const provider = config.isAgfarmus ? 'agfarmus' : 'email';

/** AUTH */
export function signIn(email, password) {
  return axios.post(`${config.endpoint}/auth/sign_in`, { email, password, provider });
}

export function signOut() {
  return axios.delete(`${config.endpoint}/auth/sign_out`);
}

export function forgotPassword(email) {
  return axios.post(`${config.endpoint}/auth/password`, { email, provider, redirect_url: config.redirectUrl });
}

export function resetPassword(password, passwordConfirmation) {
  return axios.put(`${config.endpoint}/auth/password`, { password, password_confirmation: passwordConfirmation });
}

export function validateToken() {
  return axios.get(`${config.endpoint}/auth/validate_token`);
}

export function updateAccount(user) {
  return axios({
    url: `${config.endpoint}/auth`,
    method: 'PUT',
    skipContentType: true,
    data: userToFormData(user),
  });
}
