export const FETCH_GROWTHS_REQUEST = 'FETCH_GROWTHS_REQUEST';
export const FETCH_GROWTHS_SUCCESS = 'FETCH_GROWTHS_SUCCESS';
export const FETCH_GROWTHS_FAILURE = 'FETCH_GROWTHS_FAILURE';

export const FETCH_GROWTH_REQUEST = 'FETCH_GROWTH_REQUEST';
export const FETCH_GROWTH_SUCCESS = 'FETCH_GROWTH_SUCCESS';
export const FETCH_GROWTH_FAILURE = 'FETCH_GROWTH_FAILURE';
export const CREATE_GROWTH_REQUEST = 'CREATE_GROWTH_REQUEST';
export const CREATE_GROWTH_SUCCESS = 'CREATE_GROWTH_SUCCESS';
export const CREATE_GROWTH_FAILURE = 'CREATE_GROWTH_FAILURE';
export const UPDATE_GROWTH_REQUEST = 'UPDATE_GROWTH_REQUEST';
export const UPDATE_GROWTH_SUCCESS = 'UPDATE_GROWTH_SUCCESS';
export const UPDATE_GROWTH_FAILURE = 'UPDATE_GROWTH_FAILURE';
export const DELETE_GROWTH_REQUEST = 'DELETE_GROWTH_REQUEST';
export const DELETE_GROWTH_SUCCESS = 'DELETE_GROWTH_SUCCESS';
export const DELETE_GROWTH_FAILURE = 'DELETE_GROWTH_FAILURE';
export const RESET_GROWTH = 'RESET_GROWTH';
