export const FETCH_WATCHER_REPORTS_REQUEST = 'FETCH_WATCHER_REPORTS_REQUEST';
export const FETCH_WATCHER_REPORTS_SUCCESS = 'FETCH_WATCHER_REPORTS_SUCCESS';
export const FETCH_WATCHER_REPORTS_FAILURE = 'FETCH_WATCHER_REPORTS_FAILURE';

export const FETCH_WATCHER_REPORT_REQUEST = 'FETCH_WATCHER_REPORT_REQUEST';
export const FETCH_WATCHER_REPORT_SUCCESS = 'FETCH_WATCHER_REPORT_SUCCESS';
export const FETCH_WATCHER_REPORT_FAILURE = 'FETCH_WATCHER_REPORT_FAILURE';

export const DELETE_WATCHER_REPORT_REQUEST = 'DELETE_WATCHER_REPORT_REQUEST';
export const DELETE_WATCHER_REPORT_SUCCESS = 'DELETE_WATCHER_REPORT_SUCCESS';
export const DELETE_WATCHER_REPORT_FAILURE = 'DELETE_WATCHER_REPORT_FAILURE';

export const RESET_WATCHER_REPORT = 'RESET_WATCHER_REPORT';
