import { arrayMove } from 'react-sortable-hoc';
import {
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  UPDATE_PROJECT_ENVIRONMENT_MAP_REQUEST,
  UPDATE_PROJECT_ENVIRONMENT_MAP_SUCCESS,
  UPDATE_PROJECT_ENVIRONMENT_MAP_FAILURE,
  UPDATE_PROJECT_GENERAL_CONSIDERATIONS_REQUEST,
  UPDATE_PROJECT_GENERAL_CONSIDERATIONS_SUCCESS,
  UPDATE_PROJECT_GENERAL_CONSIDERATIONS_FAILURE,
  CREATE_PROJECT_SECTION_REQUEST,
  CREATE_PROJECT_SECTION_SUCCESS,
  CREATE_PROJECT_SECTION_FAILURE,
  UPDATE_PROJECT_SECTION_REQUEST,
  UPDATE_PROJECT_SECTION_SUCCESS,
  UPDATE_PROJECT_SECTION_FAILURE,
  UPDATE_PROJECT_SECTION_POSITION_REQUEST,
  UPDATE_PROJECT_SECTION_POSITION_FAILURE,
  DELETE_PROJECT_SECTION_REQUEST,
  DELETE_PROJECT_SECTION_SUCCESS,
  DELETE_PROJECT_SECTION_FAILURE,
} from '../constants/projects';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isDeleting: false,
  isDeleted: false,

  isEnvironmentMapSaving: false,
  isEnvironmentMapSaved: false,

  isGeneralConsiderationsSaving: false,
  isGeneralConsiderationsSaved: false,

  isSectionCreating: false,
  isSectionCreated: false,
  isSectionSaving: false,
  isSectionSaved: false,
  isSectionDeleting: false,
  isSectionDeleted: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_PROJECT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: null,
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isCreating: false,
        isCreated: true,
        error: null,
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: action.error,
      };
    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        error: null,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isDeleting: false,
        isDeleted: true,
        error: null,
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: action.error,
      };
    case UPDATE_PROJECT_ENVIRONMENT_MAP_REQUEST:
      return {
        ...state,
        isEnvironmentMapSaving: true,
        isEnvironmentMapSaved: false,
        error: action.error,
      };
    case UPDATE_PROJECT_ENVIRONMENT_MAP_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isEnvironmentMapSaving: false,
        isEnvironmentMapSaved: true,
        error: action.error,
      };
    case UPDATE_PROJECT_ENVIRONMENT_MAP_FAILURE:
      return {
        ...state,
        isEnvironmentMapSaving: false,
        isEnvironmentMapSaved: false,
        error: action.error,
      };
    case UPDATE_PROJECT_GENERAL_CONSIDERATIONS_REQUEST:
      return {
        ...state,
        isGeneralConsiderationsSaving: true,
        isGeneralConsiderationsSaved: false,
        error: action.error,
      };
    case UPDATE_PROJECT_GENERAL_CONSIDERATIONS_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isGeneralConsiderationsSaving: false,
        isGeneralConsiderationsSaved: true,
        error: action.error,
      };
    case UPDATE_PROJECT_GENERAL_CONSIDERATIONS_FAILURE:
      return {
        ...state,
        isGeneralConsiderationsSaving: false,
        isGeneralConsiderationsSaved: false,
        error: action.error,
      };
    case CREATE_PROJECT_SECTION_REQUEST:
      return {
        ...state,
        isSectionCreating: true,
        isSectionCreated: false,
        error: null,
      };
    case CREATE_PROJECT_SECTION_SUCCESS:
      return {
        ...state,
        record: {
          ...state.record,
          [action.payload.sectionType]: [...state.record[action.payload.sectionType], action.payload.section],
        },
        isSectionCreating: false,
        isSectionCreated: true,
        error: null,
      };
    case CREATE_PROJECT_SECTION_FAILURE:
      return {
        ...state,
        isSectionCreating: false,
        isSectionCreated: false,
        error: action.error,
      };
    case UPDATE_PROJECT_SECTION_REQUEST:
      return {
        ...state,
        isSectionSaving: true,
        isSectionSaved: false,
        error: null,
      };
    case UPDATE_PROJECT_SECTION_SUCCESS:
      return {
        ...state,
        record: {
          ...state.record,
          /* eslint-disable max-len */
          [action.payload.sectionType]: state.record[action.payload.sectionType].map(item => (item.id === action.payload.section.id ? action.payload.section : item)),
          /* eslint-enable max-len */
        },
        isSectionSaving: false,
        isSectionSaved: true,
        error: null,
      };
    case UPDATE_PROJECT_SECTION_FAILURE:
      return {
        ...state,
        isSectionSaving: false,
        isSectionSaved: false,
        error: action.error,
      };
    case DELETE_PROJECT_SECTION_REQUEST: {
      return {
        ...state,
        isSectionDeleting: true,
        isSectionDeleted: false,
        error: null,
      };
    }
    case DELETE_PROJECT_SECTION_SUCCESS:
      return {
        ...state,
        record: {
          ...state.record,
          [action.payload.sectionType]: state.record[action.payload.sectionType].filter(
            item => item.id !== action.payload.section.id,
          ),
        },
        isSectionDeleting: false,
        isSectionDeleted: true,
        error: null,
      };
    case DELETE_PROJECT_SECTION_FAILURE:
      return {
        ...state,
        isSectionDeleting: false,
        isSectionDeleted: false,
        error: action.error,
      };
    case UPDATE_PROJECT_SECTION_POSITION_REQUEST:
      return {
        ...state,
        record: {
          ...state.record,
          [action.payload.sectionType]: arrayMove(
            state.record[action.payload.sectionType],
            action.payload.oldPosition,
            action.payload.newPosition,
          ),
        },
      };
    case UPDATE_PROJECT_SECTION_POSITION_FAILURE:
      return {
        ...state,
        record: {
          ...state.record,
          [action.payload.sectionType]: arrayMove(
            state.record[action.payload.sectionType],
            action.payload.oldPosition,
            action.payload.newPosition,
          ),
        },
      };

    default:
      return state;
  }
};

export default reducer;
