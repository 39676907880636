import querystring from 'querystring';

export function fullPath(location) {
  return `${location.pathname.match(/^.*[^/]/)[0]}.json${location.search}`;
}

export function isPathEqual(location, otherLocation) {
  return fullPath(location) === fullPath(otherLocation);
}

function getAnchorSearch(location) {
  const rawAnchor = location.anchor || '';
  const arr = rawAnchor.split('?');
  return arr.length > 1 ? arr[1] : null;
}

function getSearchQs(location) {
  const rawQs = location.search || '';
  const qs = rawQs.replace('?', '');
  const qsObj = qs ? querystring.parse(qs) : {};
  return qsObj;
}

function getAnchorQs(location) {
  const anchorQs = getAnchorSearch(location);
  const anchorQsObj = anchorQs ? querystring.parse(anchorQs) : {};
  return anchorQsObj;
}

function getAllParams(location) {
  return { ...getAnchorQs(location), ...getSearchQs(location) };
}

function normalizeTokenKeys(params) {
  // normalize keys
  const normalized = params;

  if (normalized.auth_token) {
    normalized.token = params.auth_token;
    delete normalized.auth_token;
  }
  if (normalized.client_id) {
    normalized.client = normalized.client_id;
    delete normalized.client_id;
  }
  if (normalized.config) {
    normalized.endpointKey = normalized.config;
    delete normalized.config;
  }

  return normalized;
}

function stripKeys(obj, keys) {
  const striped = obj;

  keys.forEach((key) => {
    delete striped[key];
  });

  return striped;
}

function buildCredentials(location, keys) {
  const params = getAllParams(location);
  const authHeaders = {};

  keys.forEach((key) => {
    authHeaders[key] = params[key];
  });

  return normalizeTokenKeys(authHeaders);
}

function getLocationWithoutParams(currentLocation, keys) {
  // strip all values from both actual and anchor search params
  let newSearch = querystring.stringify(stripKeys(getSearchQs(currentLocation), keys));
  const newAnchorQs = querystring.stringify(stripKeys(getAnchorQs(currentLocation), keys));
  let newAnchor = (currentLocation.hash || '').split('?')[0];

  if (newSearch) {
    newSearch = `?${newSearch}`;
  }

  if (newAnchorQs) {
    newAnchor += `?${newAnchorQs}`;
  }

  if (newAnchor && !newAnchor.match(/^#/)) {
    newAnchor = `#/${newAnchor}`;
  }

  // reconstruct location with stripped auth keys
  const newLocation = currentLocation.pathname + newSearch + newAnchor;

  return newLocation;
}

export function getRedirectInfo(currentLocation) {
  if (!currentLocation) {
    return {};
  }
  const authKeys = [
    'access-token',
    'token',
    'auth_token',
    'config',
    'client',
    'client_id',
    'expiry',
    'uid',
    'reset_password',
    'account_confirmation_success',
  ];

  const authRedirectHeaders = buildCredentials(currentLocation, authKeys);
  const authRedirectPath = getLocationWithoutParams(currentLocation, authKeys);

  if (authRedirectPath !== currentLocation) {
    return { authRedirectHeaders, authRedirectPath };
  }
  return {};
}
