export const FETCH_THREATS_REQUEST = 'FETCH_THREATS_REQUEST';
export const FETCH_THREATS_SUCCESS = 'FETCH_THREATS_SUCCESS';
export const FETCH_THREATS_FAILURE = 'FETCH_THREATS_FAILURE';

export const FETCH_THREAT_REQUEST = 'FETCH_THREAT_REQUEST';
export const FETCH_THREAT_SUCCESS = 'FETCH_THREAT_SUCCESS';
export const FETCH_THREAT_FAILURE = 'FETCH_THREAT_FAILURE';
export const CREATE_THREAT_REQUEST = 'CREATE_THREAT_REQUEST';
export const CREATE_THREAT_SUCCESS = 'CREATE_THREAT_SUCCESS';
export const CREATE_THREAT_FAILURE = 'CREATE_THREAT_FAILURE';
export const UPDATE_THREAT_REQUEST = 'UPDATE_THREAT_REQUEST';
export const UPDATE_THREAT_SUCCESS = 'UPDATE_THREAT_SUCCESS';
export const UPDATE_THREAT_FAILURE = 'UPDATE_THREAT_FAILURE';
export const DELETE_THREAT_REQUEST = 'DELETE_THREAT_REQUEST';
export const DELETE_THREAT_SUCCESS = 'DELETE_THREAT_SUCCESS';
export const DELETE_THREAT_FAILURE = 'DELETE_THREAT_FAILURE';

export const RESET_THREAT = 'RESET_THREAT';
