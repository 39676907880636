export const FETCH_FARMS_REQUEST = 'FETCH_FARMS_REQUEST';
export const FETCH_FARMS_SUCCESS = 'FETCH_FARMS_SUCCESS';
export const FETCH_FARMS_FAILURE = 'FETCH_FARMS_FAILURE';
export const FETCH_FARM_REQUEST = 'FETCH_FARM_REQUEST';
export const FETCH_FARM_SUCCESS = 'FETCH_FARM_SUCCESS';
export const FETCH_FARM_FAILURE = 'FETCH_FARM_FAILURE';
export const CREATE_FARM_REQUEST = 'CREATE_FARM_REQUEST';
export const CREATE_FARM_SUCCESS = 'CREATE_FARM_SUCCESS';
export const CREATE_FARM_FAILURE = 'CREATE_FARM_FAILURE';
export const UPDATE_FARM_REQUEST = 'UPDATE_FARM_REQUEST';
export const UPDATE_FARM_SUCCESS = 'UPDATE_FARM_SUCCESS';
export const UPDATE_FARM_FAILURE = 'UPDATE_FARM_FAILURE';
export const DELETE_FARM_REQUEST = 'DELETE_FARM_REQUEST';
export const DELETE_FARM_SUCCESS = 'DELETE_FARM_SUCCESS';
export const DELETE_FARM_FAILURE = 'DELETE_FARM_FAILURE';
export const RESET_FARM = 'RESET_FARM';
