export const FETCH_SIGANEMATOIDE_SYMPTOMS_REQUEST = 'FETCH_SIGANEMATOIDE_SYMPTOMS_REQUEST';
export const FETCH_SIGANEMATOIDE_SYMPTOMS_SUCCESS = 'FETCH_SIGANEMATOIDE_SYMPTOMS_SUCCESS';
export const FETCH_SIGANEMATOIDE_SYMPTOMS_FAILURE = 'FETCH_SIGANEMATOIDE_SYMPTOMS_FAILURE';

export const FETCH_SIGANEMATOIDE_SYMPTOM_REQUEST = 'FETCH_SIGANEMATOIDE_SYMPTOM_REQUEST';
export const FETCH_SIGANEMATOIDE_SYMPTOM_SUCCESS = 'FETCH_SIGANEMATOIDE_SYMPTOM_SUCCESS';
export const FETCH_SIGANEMATOIDE_SYMPTOM_FAILURE = 'FETCH_SIGANEMATOIDE_SYMPTOM_FAILURE';
export const CREATE_SIGANEMATOIDE_SYMPTOM_REQUEST = 'CREATE_SIGANEMATOIDE_SYMPTOM_REQUEST';
export const CREATE_SIGANEMATOIDE_SYMPTOM_SUCCESS = 'CREATE_SIGANEMATOIDE_SYMPTOM_SUCCESS';
export const CREATE_SIGANEMATOIDE_SYMPTOM_FAILURE = 'CREATE_SIGANEMATOIDE_SYMPTOM_FAILURE';
export const UPDATE_SIGANEMATOIDE_SYMPTOM_REQUEST = 'UPDATE_SIGANEMATOIDE_SYMPTOM_REQUEST';
export const UPDATE_SIGANEMATOIDE_SYMPTOM_SUCCESS = 'UPDATE_SIGANEMATOIDE_SYMPTOM_SUCCESS';
export const UPDATE_SIGANEMATOIDE_SYMPTOM_FAILURE = 'UPDATE_SIGANEMATOIDE_SYMPTOM_FAILURE';
export const DELETE_SIGANEMATOIDE_SYMPTOM_REQUEST = 'DELETE_SIGANEMATOIDE_SYMPTOM_REQUEST';
export const DELETE_SIGANEMATOIDE_SYMPTOM_SUCCESS = 'DELETE_SIGANEMATOIDE_SYMPTOM_SUCCESS';
export const DELETE_SIGANEMATOIDE_SYMPTOM_FAILURE = 'DELETE_SIGANEMATOIDE_SYMPTOM_FAILURE';

export const RESET_SIGANEMATOIDE_SYMPTOM = 'RESET_SIGANEMATOIDE_SYMPTOM';
