import { FETCH_GROWTHS_REQUEST, FETCH_GROWTHS_SUCCESS, FETCH_GROWTHS_FAILURE } from '../constants/growths';

const initialState = {
  records: [],
  meta: {},
  query: '',
  sorting: [],
  isFetching: false,
  isFetched: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROWTHS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_GROWTHS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_GROWTHS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
