import {
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAILURE,
} from '../constants/reports';

const initialState = {
  record: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isSaving: false,
  isSaved: false,
  isDeleting: false,
  isDeleted: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REPORT_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: null,
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isCreating: false,
        isCreated: true,
        error: null,
      };
    case CREATE_REPORT_FAILURE:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
