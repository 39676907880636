import { DELETE_FARM_SUCCESS } from 'constants/farms';
import { DELETE_FARM_GROUP_SUCCESS } from 'constants/farmGroups';
import { UPDATE_FILTER_FARM } from '../constants';

const appCompanyIntialState = {
  filters: {},
};

export const getApp = (state) => {
  const currentCompany = state.company.record;
  if (currentCompany) return state.app[currentCompany.id] || appCompanyIntialState;
  return appCompanyIntialState;
};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER_FARM: {
      return {
        ...state,
        [action.payload.companyId]: state[action.payload.companyId]
          ? { ...state[action.payload.companyId], filters: { farmFilter: action.payload.farmFilter } }
          : { filters: { farmFilter: action.payload.farmFilter } },
      };
    }
    case DELETE_FARM_SUCCESS: {
      const company = action.payload && action.payload.group && action.payload.group.company_id;
      const filters = company && state[company] && state[company].filters;
      const farmFilter = filters && filters.farmFilter;
      if (farmFilter && !farmFilter.isGroup && farmFilter.id === action.payload.id) {
        return {
          ...state,
          [company]: { ...state[company], filters: { ...filters, farmFilter: null } },
        };
      }
      return state;
    }
    case DELETE_FARM_GROUP_SUCCESS: {
      const company = action.payload && action.payload.company_id;
      const filters = company && state[company] && state[company].filters;
      const farmFilter = filters && filters.farmFilter;
      if (farmFilter && farmFilter.isGroup && farmFilter.id === action.payload.id) {
        return {
          ...state,
          [company]: { ...state[company], filters: { ...filters, farmFilter: null } },
        };
      }
      return state;
    }
    default:
      return state;
  }
};
