// @flow

import createReducer from 'helpers/reducerHelper';

import type { Payload, AgriculturalStep } from 'types/agriculturalStep';

export default createReducer(
  {},
  {
    /* eslint-disable max-len */
    FETCH_AGRICULTURAL_STEP_SUCCESS: (state: AgriculturalStep, action: { payload: Payload }): AgriculturalStep => action.payload.record,
    /* eslint-enable max-len */
  },
);
