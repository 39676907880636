import {
  FETCH_PROJECT_ANALYSIS_REQUEST,
  FETCH_PROJECT_ANALYSIS_SUCCESS,
  FETCH_PROJECT_ANALYSIS_FAILURE,
  TOGGLE_ANALYSIS_WITH_CORRELATION,
  RESET_PROJECT_ANALYSIS,
} from 'constants/projects';

const initialState = {
  record: null,
  withCorrelation: false,
  isFetching: false,
  isFetched: false,

  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_ANALYSIS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_PROJECT_ANALYSIS_SUCCESS:
      return {
        ...state,
        record: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_PROJECT_ANALYSIS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case TOGGLE_ANALYSIS_WITH_CORRELATION:
      return {
        ...state,
        withCorrelation: !state.withCorrelation,
      };
    case RESET_PROJECT_ANALYSIS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
