export const FETCH_FARM_GROUPS_REQUEST = 'FETCH_FARM_GROUPS_REQUEST';
export const FETCH_FARM_GROUPS_SUCCESS = 'FETCH_FARM_GROUPS_SUCCESS';
export const FETCH_FARM_GROUPS_FAILURE = 'FETCH_FARM_GROUPS_FAILURE';
export const FETCH_FARM_GROUP_REQUEST = 'FETCH_FARM_GROUP_REQUEST';
export const FETCH_FARM_GROUP_SUCCESS = 'FETCH_FARM_GROUP_SUCCESS';
export const FETCH_FARM_GROUP_FAILURE = 'FETCH_FARM_GROUP_FAILURE';
export const CREATE_FARM_GROUP_REQUEST = 'CREATE_FARM_GROUP_REQUEST';
export const CREATE_FARM_GROUP_SUCCESS = 'CREATE_FARM_GROUP_SUCCESS';
export const CREATE_FARM_GROUP_FAILURE = 'CREATE_FARM_GROUP_FAILURE';
export const UPDATE_FARM_GROUP_REQUEST = 'UPDATE_FARM_GROUP_REQUEST';
export const UPDATE_FARM_GROUP_SUCCESS = 'UPDATE_FARM_GROUP_SUCCESS';
export const UPDATE_FARM_GROUP_FAILURE = 'UPDATE_FARM_GROUP_FAILURE';
export const DELETE_FARM_GROUP_REQUEST = 'DELETE_FARM_GROUP_REQUEST';
export const DELETE_FARM_GROUP_SUCCESS = 'DELETE_FARM_GROUP_SUCCESS';
export const DELETE_FARM_GROUP_FAILURE = 'DELETE_FARM_GROUP_FAILURE';
export const RESET_FARM_GROUP = 'RESET_FARM_GROUP';
