import {
  FETCH_GROWTH_VARIETIES_REQUEST,
  FETCH_GROWTH_VARIETIES_SUCCESS,
  FETCH_GROWTH_VARIETIES_FAILURE,
} from '../constants/growthVarieties';

const initialState = {
  records: [],
  meta: {},
  query: '',
  sorting: [],
  isFetching: false,
  isFetched: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROWTH_VARIETIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_GROWTH_VARIETIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case FETCH_GROWTH_VARIETIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
