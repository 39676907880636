// @flow

import type { Action } from 'types/redux';

type State = { [key: string]: boolean | void };

export default (state: State = {}, action: Action): State => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|RESET)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'SUCCESS',
  };
};
