// @flow

import createReducer from 'helpers/reducerHelper';

import type { Vehicle, Payload } from 'types/vehicle';

export default createReducer(
  {},
  {
    FETCH_VEHICLE_SUCCESS: (state: Vehicle, action: { payload: Payload }): Vehicle => action.payload.vehicle,
  },
);
