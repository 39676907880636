// @flow

const ORGANIZATION_CONFIG = {
  agrodigital: {
    id: 1,
    production: {
      endpoint: 'https://api.agrodigitalweb.com',
      redirectUrl: 'https://www.agrodigitalweb.com',
      title: 'Agrodigital',
    },
    staging: {
      endpoint: 'https://staging-api.agrodigitalweb.com',
      redirectUrl: 'https://staging.agrodigitalweb.com/#/reset_password',
      title: 'Agrodigital | Staging',
    },
    development: {
      endpoint: 'http://localhost:4000',
      redirectUrl: 'http://localhost:3000/#/reset_password',
      title: 'Agrodigital | Devlopment',
    },
  },
  agfarmus: {
    id: 2,
    production: {
      endpoint: 'https://oke-backend-prod.yvy.ag',
      redirectUrl: 'https://www.agfarmus.com/#/reset_password',
      title: 'Yvy -  A terra no seu controle',
    },
    homolog: {
      endpoint: 'https://oke-backend-homolog.yvy.ag',
      redirectUrl: 'https://oke-backend-homolog.yvy.ag/#/reset_password',
      title: 'Yvy -  A terra no seu controle | Homolog',
    },
    development: {
      endpoint: 'https://oke-backend-dev.yvy.ag/',
      redirectUrl: 'https://oke-backend-dev.yvy.ag//#/reset_password',
      title: 'Yvy -  A terra no seu controle | Development',
    },
    qa: {
      endpoint: 'https://oke-backend-qa.yvy.ag/',
      redirectUrl: 'https://oke-backend-qa.yvy.ag//#/reset_password',
      title: 'Yvy -  A terra no seu controle | QA',
    },
  },
};

const organization = process.env.REACT_APP_ORGANIZATION || 'agfarmus';
const stage = process.env.REACT_APP_STAGE || 'development';

export default {
  ...ORGANIZATION_CONFIG[organization][stage],
  organization,
  organization_id: ORGANIZATION_CONFIG[organization].id,
  isAgrodigital: organization === 'agrodigital',
  isAgfarmus: organization === 'agfarmus',
  locales: ['pt-BR', 'en', 'es'],
};
