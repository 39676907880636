// @flow

import createReducer from 'helpers/reducerHelper';

import type { Connection, Payload } from 'types/connection';

const handler = (state: Connection, action: { payload: Payload }): Connection => action.payload.connection;

export default createReducer(
  {},
  {
    CREATE_CONNECTION_SUCCESS: handler,
    FETCH_CONNECTION_SUCCESS: handler,
  },
);
