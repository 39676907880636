// @flow

import createReducer from 'helpers/reducerHelper';

import type { VehicleCategory, Payload } from 'types/vehicle/category';

export default createReducer(
  {},
  {
    FETCH_VEHICLE_CATEGORY_SUCCESS: (
      state: VehicleCategory,
      action: {
        payload: Payload,
      },
    ): VehicleCategory => action.payload.vehicle_category,
  },
);
