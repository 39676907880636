import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, withRouter } from 'react-router';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
// import { FlashBar } from 'agro-common/es/flash';
import FlashMessages from 'components/FlashMessages';
import Loading from 'components/Loading';
import authActions from 'actions/auth';

const Login = lazy(() => import('pages/Login'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Dashboard = lazy(() => import('./DashboardLayout'));

const locationHelper = locationHelperBuilder({});

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => !!state.auth.credentials.token,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: state => !state.auth.credentials.token,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const NotAuthenticatedLogin = userIsNotAuthenticated(Login);
const NotAuthenticatedForgotPassword = userIsNotAuthenticated(ForgotPassword);
const AuthenticatedResetPassword = userIsAuthenticated(ResetPassword);
const AuthenticatedDashboard = userIsAuthenticated(Dashboard);

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
  },
};

type Props = {
  classes: Object,
  credentials: Object,
  isValidated: Boolean,
  validateToken: Function,
};

class AppLayout extends React.PureComponent<Props> {
  componentDidMount() {
    const { credentials, validateToken } = this.props;
    if (credentials.token) {
      validateToken();
    }
  }

  renderContent() {
    const { credentials, isValidated } = this.props;

    if (!credentials.token || isValidated) {
      return (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/login" component={NotAuthenticatedLogin} />
            <Route exact path="/forgot_password" component={NotAuthenticatedForgotPassword} />
            <Route exact path="/reset_password" component={AuthenticatedResetPassword} />
            <Route path="/" component={AuthenticatedDashboard} />
          </Switch>
        </Suspense>
      );
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderContent()}
        <FlashMessages />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.auth.credentials,
  isValidating: state.auth.isValidating,
  isValidated: state.auth.isValidated,
});

const mapDispatchToProps = dispatch => bindActionCreators({ validateToken: authActions.validateToken }, dispatch);

export default withRouter(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(AppLayout),
  ),
);
