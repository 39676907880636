export default {
  colors: [
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
  ],
  chart: {
    backgroundColor: '#fff',
    borderWidth: 0,
    plotBackgroundColor: '#fff',
    plotShadow: false,
    plotBorderWidth: 0,
  },
  title: {
    style: {
      color: '#333',
      fontSize: '16px',
    },
  },
  subtitle: {
    style: {
      color: '#333',
    },
  },
  xAxis: {
    gridLineWidth: 0,
    gridLineColor: '#ccc',
    lineColor: '#ccc',
    tickColor: '#ccc',
    labels: {
      style: {
        color: '#666',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px',
      },
    },
  },
  yAxis: {
    minorTickInterval: null,
    lineWidth: 1,
    tickWidth: 1,
    lineColor: 'ccc',
    tickColor: '#ccc',
    gridLineColor: '#ccc',
    labels: {
      style: {
        color: '#666',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px',
      },
    },
  },
  tooltip: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    style: {
      color: '#3E576F',
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: '#333',
      },
    },
  },
  legend: {
    itemStyle: {
      color: '#333',
      fontSize: '12px',
    },
    itemHoverStyle: {
      color: '#000',
    },
    itemHiddenStyle: {
      color: '#CCC',
    },
  },
  labels: {
    style: {
      color: '#3E576F',
    },
  },

  navigation: {
    buttonOptions: {
      theme: {
        stroke: '#CCCCCC',
      },
    },
  },
};
